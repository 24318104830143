<template>
  <div class="asset-list">
    <v-row v-if="items.length > 0">
      <v-col
        v-for="assetItem in items"
        :key="assetItem.id"
        :cols="cols"
        :sm="sm"
        :md="md"
        :lg="lg"
        :xl="xl"
      >
        <asset-item :item="assetItem" class="asset-list__item" />
      </v-col>
    </v-row>
    <v-row v-if="(!items || items.length < 1) && !isLoading">
      <v-col class="asset-list__no-data">
        <span class="text-h6">{{ $trans("assets_no_data") }}</span>
      </v-col>
    </v-row>
    <v-row v-if="items.length > 0">
      <v-col>
        <v-pagination
          v-model="currentPage"
          :length="lastPaginationPage"
          color="primary"
          circle
          :disabled="isLoading"
          total-visible="7"
          @input="getAssets"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/lib/calendesk-js-library/api/api";

export default {
  name: "AssetList",
  components: {
    AssetItem: () => import("./AssetItem"),
  },
  props: {
    cols: {
      type: Number,
      default: 6,
    },
    sm: {
      type: Number,
      default: 4,
    },
    md: {
      type: Number,
      default: 3,
    },
    lg: {
      type: Number,
      default: 2,
    },
    xl: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      isLoading: true,
      items: [],
      currentPage: 1,
      lastPaginationPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      getReload: "assets/getReload",
    }),
  },
  watch: {
    getReload() {
      this.getAssets();
    },
  },
  mounted() {
    this.getAssets();
  },
  methods: {
    getAssets() {
      this.isLoading = true;
      this.$emit("loading", true);
      api
        .getAssetsUser({
          page: this.currentPage,
          limit: 18,
        })
        .then(({ data }) => {
          this.items = data.data;
          this.lastPaginationPage = data.last_page;
        })
        .catch((error) => this.$log.error(error))
        .finally(() => {
          this.$emit("loading", false);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-list__no-data {
  display: flex;
  justify-content: center;
}

.asset-list__loading {
  display: flex;
  justify-content: center;
}
</style>
